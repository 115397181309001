import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settingsDefaultState, settingsPage } from "../interfaces/SettingsInterface";
import { AppState, AppThunk } from "../store";
import { setLoading } from "./loader.reducer";
import { performDeleteRequest, performGetRequest, performPostRequest, performPutRequest, performPutRequest_FormData, performPostRequest_FormData } from "../../constants/axios-utils";
import API from "../../shared/API-end-points";
import { MyToast, hasValue, responseHandler } from "../../Utils";

export const getSettings = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performGetRequest(API.settings).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      const res_data = {
        rows: apiResponse?.data?.data ?? [],
        count: 1
      }
      dispatch(getSettingsList(res_data));
    }
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};
export const addSettings = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performPostRequest(API.settings, payload).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getSettings({}));
      navigate("/settings")
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

export const deleteSettings = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performDeleteRequest(API.settings + "/" + payload?.id ?? "").then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getSettings({}));
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

const initialState: settingsDefaultState = {
  settingsList: [],
  totalRecords: 0
};

export const settingsReducer = createSlice({
  name: "settingsState",
  initialState,
  reducers: {
    getSettingsList: (state, action: PayloadAction<settingsPage>) => ({
      ...state,
      settingsList: action.payload.rows,
      totalRecords: action.payload.count
    }),
  },
});

export const { getSettingsList } = settingsReducer.actions;

export const getSettingsListData = (state: AppState) => {
  return ({ rows: state.settingsReducer.settingsList, count: state.settingsReducer.totalRecords })
};

export { initialState as settingsState };
export default settingsReducer;
