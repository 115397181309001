import React from 'react';
import AppRouter from './router/AppRouter';
import PublicRouter from './router/PublicRouter';
import { ToastContainer } from 'react-toastify';

class App extends React.Component {

  render() {
    const isLogin = window.sessionStorage.getItem("@userToken")
    return (
      <>
        {isLogin ? <AppRouter /> : <PublicRouter />}
        <ToastContainer />
      </>
    );
  }
}

export default App;
