
// const BASE_URL = 'https://gau-maitri.clubprowin.in'
const BASE_URL = 'https://api.goumaitri.com'

const API = {
  BASE_URL: BASE_URL,
  Login: "admin/login",
  category: "admin/categories",
  breed: "admin/breeds",
  stock: "admin/stocks",
  settings: "admin/common/settings",
  enquiry: "admin/stocks/stockEnquiries",
  addMachinery: "/admin/stocks",
  banner: "admin/banners",
}

export default API

