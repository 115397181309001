import * as React from 'react';
import { useSelector } from 'react-redux';
import { getAlert } from '../../redux/reducers/Alert.reducer'

const Success = () => {
    
    const alertData: any = useSelector(getAlert);    

    if (alertData && alertData.display && alertData.type === "success") {
        return (
            <ShowSuccess title={alertData.title} />
        );
    } else if (alertData && alertData.display && alertData.type === "warning") {
        return (
            <ShowWarning title={alertData.title} />
        );
    } else {
        return null;    
    }
}


function ShowSuccess ({title}: {title: string}) {
    return (
        <div className="alert alert-success border-0" role="alert">
            {title}
        </div>
    );    
}

function ShowWarning ({title}: {title: string}) {
    return (
        <div className="alert alert-warning border-0" role="alert">
            {title}
        </div>
    );    
}
export { Success };