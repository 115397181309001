import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import Capatilize from "../../../common/components/Capatilize"

const Header = () => {

    const [fullName, setFullName] = useState('');
    var userData: any = window.sessionStorage.getItem("@userData");

    useEffect(() => {

        if (userData) {
            userData = JSON.parse(userData);
            setFullName(userData.full_name);
        }

    }, [])



    const logout = () => {
        window.sessionStorage.setItem("@userToken", "")
        //navigate('/login');
        window.location.href = '/login';
    }

    return (
        <header className="main-header">
            <div className="container_header">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="logo">
                    {/* <a href="javascript::void(0)">
                        <strong className="logo_icon">
                            <img src="assets/images/logo.png" alt="" />
                        </strong> */}
                    <span className="logo-default">
                        <img style={{ height: 70, width: 200 }} src="assets/images/logo.png" alt="" />
                    </span>
                    {/* </a> */}
                </div>
                <div className="right_detail">
                    <div className="row row d-flex align-items-center min-h pos-md-r">
                        <div className="col-xl-5 col-3 search_col ">
                            <div className="top_function d-md-flex align-items-md-center">
                                <div className="icon_menu">
                                    {/* <a href="javascript::void(0)" className="menu-toggler sidebar-toggler">
                                        <i className="icon-menu"></i>
                                    </a> */}
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-7 col-9 d-flex justify-content-end">
                            <div className="right_bar_top d-flex align-items-center">

                                <div className="dropdown dropdown-user">
                                    <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true">
                                        <img className="img-circle pro_pic" src="assets/images/avatar.jpg" alt="" />
                                        <span className="username"> {Capatilize(fullName)} </span>
                                        <i className="fa fa-angle-down"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-default">
                                        <li>
                                            <a href='#' onClick={() => logout()}>
                                                <i className="icon-logout"></i> Log Out </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
}

export default Header;