import {
  setUserManagementData
} from "./userManagementReducer";
import { AppThunk } from "../store";
import { setUserData, setToken } from '../../helper/Storage';
import API from "../../shared/API-end-points";
import { performPostRequest } from "../../constants/axios-utils";
import { MyToast, hasValue, responseHandler } from "../../Utils";

export const onLogin = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  let form_data = {
    email: payload.email.value,
    password: payload.password.value
  }
  await performPostRequest(API.Login, form_data).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      const resData = apiResponse?.data?.data[0]
      const userObj: any = JSON.stringify(resData?.admin ?? "");
      setUserData(userObj);
      setToken(resData?.accessToken);
      dispatch(setUserManagementData(userObj));
      navigate('home');
      window.location.href = '/';
    }
    MyToast(apiResponse?.data?.msg ?? "")
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
  })
};