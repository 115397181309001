import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { useNavigate, useLocation } from "react-router-dom";
import { hasValue, dateTime } from '../../../Utils';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { Loader } from '../../../common/components/Loader';
import { getCategoryListData } from '../../../redux/reducers/category.reducer';
import { getCategory } from '../../../redux/reducers/category.reducer';
import { getBreedListData } from '../../../redux/reducers/breed.reducer';
import { getBreed } from '../../../redux/reducers/breed.reducer';
import { DateTime } from '../../../common/components/Date';

const AddEnquiry = () => {
    const loader = useSelector(getLoading);
    let location = useLocation();
    const { itemData } = location.state;
    const categoryListData = useSelector(getCategoryListData);
    const breedListData = useSelector(getBreedListData);
    console.log(itemData, 'itemData');
    const breadCrums = [{ link: 'Enquiry', title: 'Enquiry' }];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState([]);
    const [breedData, setBreedData] = useState([]);
    const [frontImage, setFrontImage] = useState("");
    const [backImage, setBackImage] = useState("");
    const [leftImage, setLeftImage] = useState("");
    const [rightImage, setRightImage] = useState("");
    const [productType, setProductType] = useState([
        {
            key: "buy_sell",
            value: "Buy Sell"
        },
        {
            key: "e_market",
            value: "E Market"
        },
    ]);

    const [formState, setFormState] = useState({
        name: { error: "", value: "" },
        brand: { error: "", value: "" },
        category: { error: "", value: "" },
        breed: { error: "", value: "" },
        price: { error: "", value: "" },
        quantity: { error: "", value: "" },
        description: { error: "", value: "" },
        frontImage: { error: "", value: "" },
        backImage: { error: "", value: "" },
        leftImage: { error: "", value: "" },
        rightImage: { error: "", value: "" },
        productType: { error: "", value: "" },
        pinCode: { error: "", value: "" }
    });
    function hasFormValueChanged(model) {
        // setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    useEffect(() => {
        try {
            dispatch(getCategory({}));
            const id = itemData?.id ?? ""
            const itemDetails = itemData?.stock ?? ""
            if (hasValue(id)) {
                let frontImage = ""
                let backImage = ""
                let rightImage = ""
                let leftImage = ""
                if (hasValue(itemDetails?.stockImages ?? "") && itemDetails.stockImages.length > 0) {
                    itemDetails.stockImages.forEach(element => {
                        if (element.type === "front") {
                            frontImage = element.path
                        }
                        if (element.type === "back") {
                            backImage = element.path
                        }
                        if (element.type === "right") {
                            rightImage = element.path
                        }
                        if (element.type === "left") {
                            leftImage = element.path
                        }
                    });
                }
                setFrontImage(frontImage)
                setBackImage(backImage)
                setRightImage(rightImage)
                setLeftImage(leftImage)
                setFormState({
                    name: { error: "", value: itemDetails?.name ?? "" },
                    brand: { error: "", value: itemDetails?.brand ?? "" },
                    category: { error: "", value: itemDetails?.category?.name ?? "" },
                    breed: { error: "", value: itemDetails?.breed?.name ?? "" },
                    price: { error: "", value: itemDetails?.price ?? "" },
                    quantity: { error: "", value: itemData?.quantity ?? "" },
                    description: { error: "", value: itemDetails?.description ?? "" },
                    productType: { error: "", value: itemDetails?.productType ?? "" },
                    pinCode: { error: "", value: itemData?.pinCode ?? "" },
                    frontImage: { error: "", value: "" },
                    backImage: { error: "", value: "" },
                    rightImage: { error: "", value: "" },
                    leftImage: { error: "", value: "" }
                })
                dispatch(getBreed({
                    page: 1,
                    limit: 10,
                    loading: false,
                    url: "?categoryId=" + itemDetails?.categoryId ?? ""
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }, [itemData]);

    console.log(formState, 'formState');

    useEffect(() => {
        try {
            const listData = categoryListData?.rows ?? []
            let tmpData = []
            listData.forEach(element => {
                tmpData.push({
                    key: element.id, value: element.name
                })
            });
            setCategoryData(tmpData)
        } catch (error) {
            console.log(error);
        }
    }, [categoryListData]);

    useEffect(() => {
        try {
            const listData = breedListData?.rows ?? []
            let tmpData = []
            listData.forEach(element => {
                tmpData.push({
                    key: element.id, value: element.name
                })
            });
            setBreedData(tmpData)
        } catch (error) {
            console.log(error);
        }
    }, [breedListData]);

    function isFormInvalid() {
        return (
            formState.name.error || !formState.name.value &&
            formState.brand.error || !formState.brand.value &&
            formState.categoryId.error || !formState.categoryId.value &&
            formState.breedId.error || !formState.breedId.value &&
            formState.price.error || !formState.price.value &&
            formState.quantity.error || !formState.quantity.value &&
            formState.description.error || !formState.description.value
            // formState.frontImage.error || !formState.frontImage.value &&
            // formState.backImage.error || !formState.backImage.value &&
            // formState.rightImage.error || !formState.rightImage.value &&
            // formState.leftImage.error || !formState.leftImage.value
        );
    }
    function getDisabledClass() {
        let isError = isFormInvalid();
        return isError ? "disabled" : "";
    }

    function onFrontImage(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setFrontImage(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "frontImage"
            })
        }
    };
    function onBackImage(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setBackImage(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "backImage"
            })
        }
    };
    function onLeftImage(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setLeftImage(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "leftImage"
            })
        }
    };
    function onRightImage(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setRightImage(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "rightImage"
            })
        }
    };
    const onPressCategory = (e) => {
        hasFormValueChanged({
            "value": e.target.value,
            "error": "",
            "touched": false,
            "field": "categoryId"
        })
        dispatch(getBreed({
            page: 1,
            limit: 10,
            loading: false,
            url: "?categoryId=" + e.target.value
        }))
    };

    const renderCategory = () => {
        return categoryData.map((item) => {
            return (
                <option value={item.key} key={item.value}>
                    {item.value}
                </option>
            );
        });
    };
    const onPressBreed = (e) => {
        hasFormValueChanged({
            "value": e.target.value,
            "error": "",
            "touched": false,
            "field": "breedId"
        })
    };

    const renderBreed = () => {
        return breedData.map((item) => {
            return (
                <option value={item.key} key={item.value}>
                    {item.value}
                </option>
            );
        });
    };

    const onPressProductType = (e) => {
        hasFormValueChanged({
            "value": e.target.value,
            "error": "",
            "touched": false,
            "field": "productType"
        })
    };

    const renderProductType = () => {
        return productType.map((item) => {
            return (
                <option value={item.key} key={item.value}>
                    {item.value}
                </option>
            );
        });
    };

    function renderAddress(item) {
        try {
            let data = ""
            if (hasValue(item?.addresses ?? "") && item.addresses.length > 0) {
                const addresses = item.addresses[0]
                data = `${hasValue(addresses?.street ?? "") ? `${addresses?.street}` : ""}${hasValue(addresses?.city ?? "") ? `, ${addresses?.city}` : ""}${hasValue(addresses?.state ?? "") ? `, ${addresses?.state}` : ""}${hasValue(addresses?.postalCode ?? "") ? `, ${addresses?.postalCode}` : ""}`
            }
            return data
        } catch (error) {
            console.log(error);
            return ""
        }
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Enquiry'} page={breadCrums} />
                    {loader ?
                        (<Loader />) :
                        (<div className="row">
                            <div className=" col-md-12">
                                <div className="card card-shadow mb-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            Enquiry Details
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="user" action='javascript:void(0)'>
                                            <div className="form-group">
                                                <TextInput id="UserName"
                                                    field="UserName"
                                                    value={itemData?.user?.name ?? ""}
                                                    required={true}
                                                    label="Enquiry By"
                                                    placeholder="Enquiry By"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="ContactNumber"
                                                    field="ContactNumber"
                                                    value={itemData?.user?.mobileNo ?? ""}
                                                    required={true}
                                                    label="Contact Number"
                                                    placeholder="Contact No."
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="Address"
                                                    field="Address"
                                                    value={renderAddress(itemData?.user ?? "")}
                                                    required={true}
                                                    label="Address"
                                                    placeholder="Address"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="EnquiryDate"
                                                    field="EnquiryDate"
                                                    // value={<DateTime date={itemData?.createdAt ?? ""} />}
                                                    value={dateTime(itemData?.createdAt ?? "")}
                                                    required={true}
                                                    label="Enquiry Date"
                                                    placeholder="Enquiry Date"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="name"
                                                    field="name"
                                                    value={formState.name.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Name"
                                                    placeholder="Name"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="StockUId"
                                                    field="StockUId"
                                                    value={itemData?.stock?.stockUid}
                                                    required={true}
                                                    label="Stock UId"
                                                    placeholder="Stock UId"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="brand"
                                                    field="brand"
                                                    value={formState.brand.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Brand"
                                                    placeholder="Brand"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="price"
                                                    field="price"
                                                    value={formState.price.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Price"
                                                    placeholder="Price"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="quantity"
                                                    field="quantity"
                                                    value={formState.quantity.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Quantity"
                                                    placeholder="Quantity"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="category"
                                                    field="category"
                                                    value={formState.category.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Category"
                                                    placeholder="Category"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="breed"
                                                    field="breed"
                                                    value={formState.breed.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Breed"
                                                    placeholder="Breed"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="productType"
                                                    field="productType"
                                                    value={formState.productType.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Product Type"
                                                    placeholder="Product Type"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="pinCode"
                                                    field="pinCode"
                                                    value={formState.pinCode.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Pin Code"
                                                    placeholder="Pin Code"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="UserName"
                                                    field="UserName"
                                                    value={itemData?.stock?.user?.name ?? ""}
                                                    required={true}
                                                    label="Created By"
                                                    placeholder="Created By"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="ContactNumber"
                                                    field="ContactNumber"
                                                    value={itemData?.stock?.user?.mobileNo ?? ""}
                                                    required={true}
                                                    label="Contact Number"
                                                    placeholder="Contact No."
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="Address"
                                                    field="Address"
                                                    value={renderAddress(itemData?.stock?.user ?? "")}
                                                    required={true}
                                                    label="Address"
                                                    placeholder="Address"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput id="description"
                                                    field="description"
                                                    value={formState.description.value}
                                                    // onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Description"
                                                    placeholder="Description"
                                                    onChange={hasFormValueChanged}
                                                />
                                            </div>
                                            <div>
                                                <label>Front side image</label>
                                                <div className="form-group">
                                                    {frontImage &&
                                                        <img style={{ height: 150, width: 150 }} src={frontImage} />
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <label>Back side image</label>
                                                <div className="form-group">
                                                    {backImage &&
                                                        <img style={{ height: 150, width: 150 }} src={backImage} />
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <label>Right side image</label>
                                                <div className="form-group">
                                                    {rightImage &&
                                                        <img style={{ height: 150, width: 150 }} src={rightImage} />
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label>Left side image</label>
                                                <div className="form-group">
                                                    {leftImage &&
                                                        <img style={{ height: 150, width: 150 }} src={leftImage} />
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default AddEnquiry;

