import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { addCategory } from '../../../redux/reducers/category.reducer';
import { useNavigate, useLocation } from "react-router-dom";
import { hasValue } from '../../../Utils';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { Loader } from '../../../common/components/Loader';

const AddCategory = () => {
    const loader = useSelector(getLoading);
    let location: any = useLocation();
    const { itemData } = location.state;
    console.log(itemData, 'itemData');
    const breadCrums = [{ link: 'Category', title: 'Category' }];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState("");

    const [formState, setFormState] = useState({
        name: { error: "", value: "" },
        file: { error: "", value: "" }
    });
    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    useEffect(() => {
        const id = itemData?.id ?? ""
        if (hasValue(id)) {
            setFormState({
                name: { error: "", value: itemData?.name ?? "" },
                file: { error: "", value: "" },
                // file: { error: "", value: itemData?.path ?? "" },
            })
            setFile(itemData?.path ?? "")
        }
    }, [itemData]);

    const submit = async () => {
        try {
            if (isFormInvalid()) {
                return
            }
            let payloads = {}
            if (hasValue(itemData?.id ?? "")) {
                payloads = {
                    id: itemData?.id ?? "",
                    name: formState.name.value,
                    file: formState.file.value
                }
            } else {
                payloads = {
                    name: formState.name.value,
                    file: formState.file.value
                }
            }
            dispatch(addCategory(payloads, navigate));
        } catch (error) {
            console.log(error);
        }
    }
    console.log(loader, 'loader');

    function isFormInvalid() {
        return (formState.name.error || !formState.name.value);
    }
    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    function onImageChange(event: any) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setFile(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "file"
            })
        }
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={hasValue(itemData) ? 'Update Category' : 'Add Category'} page={breadCrums} />
                    {loader ?
                        (<Loader />) :
                        (<div className="row">
                            <div className=" col-md-12">
                                <div className="card card-shadow mb-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            Category
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                            <div className="form-group">
                                                <TextInput id="name"
                                                    field="name"
                                                    value={formState.name.value}
                                                    onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Name"
                                                    placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                {file &&
                                                    <img style={{ height: 150, width: 150 }} src={file} />
                                                }
                                                <input type="file" name="myImage" onChange={onImageChange} />
                                            </div>
                                            <button
                                                className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                                type="submit" >
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default AddCategory;

