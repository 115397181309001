
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

export const hasValue = (data) => {
  return (data !== undefined) && (data !== null) && (data !== "");
}
export const MyToast = function (msg) {
  try {
    if (hasValue(msg)) {
      toast(msg)
    }
  } catch (error) {
    console.log(error);
  }
}
export const responseHandler = (response) => {
  try {
    if (hasValue(response)) {
      console.log(response?.config?.url ?? "" + ' :- ', response);
      if (hasValue(response?.status ?? "") && (response.status < 200 || response.status >= 300)) {
        var error = response?.data?.message ?? null
        if (response.status == 401) { // Unauthorized
          // RootNavigation.replace('Login'); 
        }
        MyToast(error);
        return null
      } else {
        const response_status = response?.status ?? null
        const status = response?.data?.status ?? null
        const success = response?.data?.success ?? null
        if (response_status == 200 || status == true || status == 200 || status == "success" || success == true || success == "success") {
          return response
        } else {
          MyToast(error);
          return null
        }
      }
    } else {
      return null
    }
  } catch (error) {
    console.log('responseHandler error:- ', error);
    return null
  }
}

export const dateTime = (data, prev_format, show_format) => {
  let res = moment(data ? data : new Date(), prev_format).format(show_format ? show_format : 'D, MMM YYYY hh:mm')
  if (!hasValue(res)) { res = "" }
  if (res == 'Invalid date') { res = "" }
  return res;
}