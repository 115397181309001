import * as React from 'react';
import SideBar from '../layout/sidebar/SideBar';
import './index.css';
import BreadCrum from '../layout';
import { Link } from 'react-router-dom';

const Home = () => {

    const breadCrums = [{ link: 'dashboards', title: 'Dashboards' }];

    return (

        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">

                    {/* <BreadCrum title={'Dashboard'} page={breadCrums} /> */}

                    {/* <div className="site_view">
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="info_items bg_green d-flex align-items-center">
                                    <span className="info_items_icon">
                                        <i className="ion-android-people"></i>
                                    </span>
                                    <div className="info_item_content">
                                        <span className="info_items_text">New users</span>
                                        <span className="info_items_number">450</span>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: '45%' }}></div>
                                        </div>
                                        <span className="progress-description"> 45% Increase in 28 Days </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="info_items bg_yellow d-flex align-items-center">
                                    <span className="info_items_icon">
                                        <i className="ion-ios-person"></i>
                                    </span>
                                    <div className="info_item_content">
                                        <span className="info_items_text">Avg Time</span>
                                        <span className="info_items_number">155</span>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: '40%' }}></div>
                                        </div>
                                        <span className="progress-description"> 40% Increase in 28 Days </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="info_items bg_blue d-flex align-items-center">
                                    <span className="info_items_icon">
                                        <i className="ion-ios-book"></i>
                                    </span>
                                    <div className="info_item_content">
                                        <span className="info_items_text">Total Prodcut</span>
                                        <span className="info_items_number">52</span>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: '85%' }}></div>
                                        </div>
                                        <span className="progress-description"> 85% Increase in 28 Days </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="bg_pink info_items d-flex align-items-center">
                                    <span className="info_items_icon">
                                        <i className="ion-social-usd"></i>
                                    </span>
                                    <div className="info_item_content">
                                        <span className="info_items_text">Sales</span>
                                        <span className="info_items_number">13,921</span>
                                        <span>$</span>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: '50%' }}></div>
                                        </div>
                                        <span className="progress-description"> 50% Increase in 28 Days </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> 

                   <div className="chart_section">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Product Sales</h3>
                                        </div>
                                        <div className="tools">
                                            <Link className="fa fa-repeat btn-color box-refresh" to={'/'}></Link>
                                            <Link className="t-collapse btn-color fa fa-chevron-down" to={'/'}></Link>
                                            <Link className="t-close btn-color fa fa-times" to={'/'}></Link>
                                        </div>
                                    </div>
                                    <div className="card_chart">
                                        <div className="chart_show">
                                            <canvas id="chartjs_line"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Top Sales Countries</h3>
                                        </div>

                                    </div>
                                    <div className="card_chart">
                                        <div id="canvas-holder">
                                            <canvas id="chartjs_pie"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                  <section className="more_detail_box">
                        <div className="row">
                            <div className="col-lg-4 d-flex align-items-stretch">
                                <div className="stats-wrap full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Sales</h3>
                                        </div>
                                        <div className="tools">
                                            <button id="as" className="fa fa-repeat btn-color box-refresh" ></button>
                                            <button id="as1" className="t-collapse btn-color fa fa-chevron-down" ></button>
                                            <button id="as2" className="t-close btn-color fa fa-times" ></button>
                                        </div>
                                    </div>
                                    <div className="card_chart">
                                        <div className="stats-wrap">
                                            <h2>
                                                <b>65,800</b>
                                                <span>+
                                                    <b>20</b>%</span>
                                            </h2>
                                            <p className="text-grey">
                                                Total Sale
                                                <small>This Year</small>
                                            </p>
                                            <h4>
                                                <b>1,204</b>
                                                <span>+
                                                    <b>5</b>%</span>
                                            </h4>
                                            <p>
                                                Sale
                                                <small>This week</small>
                                            </p>
                                            <h4>
                                                <b>2,690</b>
                                                <span>+
                                                    <b>125</b>
                                                </span>
                                            </h4>
                                            <p>
                                                New Sales
                                                <small>This Month</small>
                                            </p>
                                            <h4>
                                                <b>2,690</b>
                                                <span>+
                                                    <b>125</b>
                                                </span>
                                            </h4>
                                            <p>
                                                New Sale
                                                <small>This Month</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex align-items-stretch">
                                <div className="stats-wrap full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Activity</h3>
                                        </div>
                                        <div className="tools">
                                            <a className="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                            <a className="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                            <a className="t-close btn-color fa fa-times" href="javascript:;"></a>
                                        </div>
                                    </div>
                                    <div className="card_chart">
                                        <div className="events-nest">
                                            <div className="drak_blue">
                                                <h1>25</h1>
                                                <div className="middle_text">
                                                    <span>3 Event</span>
                                                    <p>
                                                        Monday
                                                        <i className="fontello-record"></i> february 2019
                                                    </p>
                                                </div>
                                            </div>
                                            <ul>
                                                <li>
                                                    <h4>
                                                        <span className="counter-up-fast">8</span>:00
                                                        <small>PM</small>
                                                    </h4>
                                                    <p>
                                                        Mathematic Lesson
                                                        <i className="ion-ios-copy-outline"></i>
                                                        <i className="ion-ios-heart-outline"></i>
                                                    </p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span className="counter-up-fast">9</span>:
                                                        <span className="counter-up-fast">30</span>
                                                        <small>PM</small>
                                                    </h4>
                                                    <p>
                                                        Meeting With Josh
                                                        <i className="ion-ios-copy-outline"></i>
                                                        <i className="ion-ios-heart-outline"></i>
                                                    </p>
                                                </li>
                                                <li>
                                                    <h4>
                                                        <span className="counter-up-fast">12</span>:
                                                        <span className="counter-up-fast">15</span>
                                                        <small>PM</small>
                                                    </h4>
                                                    <p>
                                                        Launch time
                                                        <i className="ion-ios-copy-outline"></i>
                                                        <i className="ion-ios-heart-outline"></i>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex align-items-stretch">
                                <div className="stats-wrap full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Timetable</h3>
                                        </div>
                                        <div className="tools">
                                            <a className="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                            <a className="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                            <a className="t-close btn-color fa fa-times" href="javascript:;"></a>
                                        </div>
                                    </div>
                                    <div className="card_chart">
                                        <div className="school-timetable">
                                            <h5>
                                                <strong>English </strong>with
                                                <i> William Shakespear</i>
                                            </h5>
                                            <h6>
                                                <i className="ion-android-home"></i> Room
                                                <span>104 B</span>
                                            </h6>
                                            <h6>
                                                <i className="ion-android-time"></i> Mon, October 21st
                                                <span>10.00 AM - 12.00 AM</span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img1.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img2.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img3.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img4.jpg" alt="" />
                                                </li>
                                            </ul>
                                            <hr />
                                            <h5>
                                                <strong>Mathematic </strong>with
                                                <i> Albert Enstein</i>
                                            </h5>
                                            <h6>
                                                <i className="ion-android-home"></i> Room
                                                <span>98 C</span>
                                            </h6>
                                            <h6>
                                                <i className="ion-android-time"></i> Fri, November 1st
                                                <span>09.00 AM - 10.00 AM</span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img1.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img2.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img3.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <img className="img-circle round_small" src="assets/images/img4.jpg" alt="" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="student_list">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="full_chart card">
                                    <div className="chart_header">
                                        <div className="chart_headibg">
                                            <h3>Social Campaigns</h3>
                                        </div>
                                        <div className="tools">
                                            <a className="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                            <a className="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                            <a className="t-close btn-color fa fa-times" href="javascript:;"></a>
                                        </div>
                                    </div>
                                    <div className="card_chart">
                                        <div className="student_table table-responsive-lg">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Campaign</th>
                                                        <th>Client</th>
                                                        <th>Changes</th>
                                                        <th>Budget</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span className="txt-dark weight-500">Facebook</span>
                                                        </td>
                                                        <td>Beavis</td>
                                                        <td>
                                                            <span className="txt-success">
                                                                <i className="fa fa-sort-up mr-10 "></i>
                                                                <span>2.43%</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="txt-dark weight-500">$1478</span>
                                                        </td>
                                                        <td>
                                                            <span className="label label-warning">Active</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="txt-dark weight-500">Youtube</span>
                                                        </td>
                                                        <td>Felix</td>
                                                        <td>
                                                            <span className="txt-success">
                                                                <i className="fa fa-sort-up mr-10 "></i>
                                                                <span>1.43%</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="txt-dark weight-500">$951</span>
                                                        </td>
                                                        <td>
                                                            <span className="label label-danger">Closed</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="txt-dark weight-500">Twitter</span>
                                                        </td>
                                                        <td>Cannibus</td>
                                                        <td>
                                                            <span className="txt-danger">
                                                                <i className="fa fa-sort-down mr-10 "></i>
                                                                <span>-8.43%</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="txt-dark weight-500">$632</span>
                                                        </td>
                                                        <td>
                                                            <span className="label label-default">Hold</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="txt-dark weight-500">Spotify</span>
                                                        </td>
                                                        <td>Neosoft</td>
                                                        <td>
                                                            <span className="txt-success">
                                                                <i className="fa fa-sort-up mr-10 "></i>
                                                                <span>7.43%</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="txt-dark weight-500">$325</span>
                                                        </td>
                                                        <td>
                                                            <span className="label label-default">Hold</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="social_media">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="social-box facebook">
                                    <i className="fa fa-facebook"></i>
                                    <ul>
                                        <li>
                                            <strong>
                                                <span className="count">40</span> k</strong>
                                            <span>friends</span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span className="count">450</span>
                                            </strong>
                                            <span>feeds</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="social-box twitter">
                                    <i className="fa fa-twitter"></i>
                                    <ul>
                                        <li>
                                            <strong>
                                                <span className="count">30</span> k</strong>
                                            <span>friends</span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span className="count">450</span>
                                            </strong>
                                            <span>tweets</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="social-box linkedin">
                                    <i className="fa fa-linkedin"></i>
                                    <ul>
                                        <li>
                                            <strong>
                                                <span className="count">40</span> +</strong>
                                            <span>contacts</span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span className="count">250</span>
                                            </strong>
                                            <span>feeds</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="social-box google-plus">
                                    <i className="fa fa-google-plus"></i>
                                    <ul>
                                        <li>
                                            <strong>
                                                <span className="count">94</span> k</strong>
                                            <span>followers</span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span className="count">92</span>
                                            </strong>
                                            <span>circles</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section> */}

                </div>
            </div>
        </div>

    );
}

export default Home;
