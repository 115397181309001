import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { getEnquiryListData } from '../../../redux/reducers/enquiry.reducer';
import { enquiryItem } from '../../../redux/interfaces/EnquiryInterface';
import { getEnquiry, deleteEnquiry } from '../../../redux/reducers/enquiry.reducer';
import swal from 'sweetalert';

function EnquiryList() {
  const dispatch = useDispatch();
  const itemData = useSelector(getEnquiryListData);
  const breadCrums = [{ link: 'Enquiry', title: 'Enquiry' }];
  const loader = useSelector(getLoading);

  const [listData, setListData] = useState<enquiryItem[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const filterRow = (row: any) => {
    const copyrow = { ...row }
    delete copyrow.tag
    delete copyrow.deletedAt
    return [copyrow]
  }

  const DeleteRecord = async (payload: any) => {
    dispatch(deleteEnquiry(payload));
  }
  const deleteEnquiryBatch = (payload: {}) => {
    swal("Are you sure?", "You want to delete this!", "warning", {
      buttons: ["CANCEL", "DELETE"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        DeleteRecord(payload);
      }
    });
  };

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, index: any) => index + 1
    },
    {
      name: 'Enquiry No.',
      selector: (row: any, index: any) => row?.id ?? ""
    },
    {
      name: 'Enquiry By',
      selector: (row: any) => row?.user?.name ?? ""
    },
    {
      name: 'Contact No.',
      selector: (row: any) => row?.user?.mobileNo ?? ""
    },
    {
      name: 'Stock UId',
      selector: (row: any) => row?.stock?.stockUid
    },
    // {
    //   name: 'Quantity',
    //   selector: (row: any) => row.quantity
    // },
    {
      name: 'Category',
      selector: (row: any) => row?.stock?.category?.name ?? ""
    },
    // {
    //   name: 'Pin Code',
    //   selector: (row: any) => row.pinCode
    // },
    {
      name: 'Product Type',
      selector: (row: any) => row?.stock?.productType
    },
    {
      name: 'Created By',
      selector: (row: any) => row?.stock?.user?.name ?? ""
    },
    {
      name: 'Contact No.',
      selector: (row: any) => row?.stock?.user?.mobileNo ?? ""
    },
    // {
    //   name: 'Created At',
    //   selector: (row: any) => row.createdAt,
    //   cell: (row: any) => <DateTime date={row.createdAt} />
    // },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link state={{ itemData: row }} className="dropdown-item" to={'AddEnquiry'}>View</Link>
              {/* <a className="dropdown-item"
                onClick={() => deleteEnquiryBatch({ id: row.id, type: "11", delete_type: "1" })}
              >Delete</a> */}
            </div>
          </div>
        );
      },
    }
  ];
  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async (page = 1, newPerPage: any) => {
    let payload = {
      data: { page: page },
      url: `?limit=${newPerPage}&offset=${page}`
    };
    dispatch(getEnquiry(payload));
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page);
    fetchData(page, newPerPage);
  };

  useEffect(() => {
    fetchData(1, perPage);
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setListData(itemData.rows);
    setTotalRows(itemData.count);
  }, [itemData]);

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Enquiry'} page={breadCrums} />
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='text-right mb-2'>
              <Link state={{ itemData: null }} className="btn btn-primary" to={'AddEnquiry'}>Create Enquiry</Link>
            </div>
          </div> */}
          <DataTable
            title="Enquiry"
            columns={columns}
            data={listData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default EnquiryList;