import { createLogger } from "redux-logger";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userManagementReducer from "./reducers/userManagementReducer";
import loaderReducer from "./reducers/loader.reducer";
import alertReducer from "./reducers/Alert.reducer";
import bannerReducer from "./reducers/banner.reducer";
import categoryReducer from "./reducers/category.reducer";
import breedReducer from "./reducers/breed.reducer";
import stockReducer from "./reducers/stock.reducer";
import settingsReducer from "./reducers/settings.reducer";
import enquiryReducer from "./reducers/enquiry.reducer";

const logger = createLogger();

export const store = configureStore({
  reducer: {
    categoryReducer: categoryReducer.reducer,
    settingsReducer: settingsReducer.reducer,
    breedReducer: breedReducer.reducer,
    stockReducer: stockReducer.reducer,
    enquiryReducer: enquiryReducer.reducer,
    bannerReducer: bannerReducer.reducer,
    userManagement: userManagementReducer.reducer,
    loaderReducer: loaderReducer.reducer,
    alertReducer: alertReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;

export type commonState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch