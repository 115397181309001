import axios from 'axios'
import API from '../shared/API-end-points';
let token = window.sessionStorage.getItem("@userToken")

export const getAxiosInstance = () => {
  const instance = axios.create({
    baseURL: API.BASE_URL,
    timeout: 3000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return instance
}

export const getAxiosInstance_FormData = () => {
  const instance = axios.create({
    baseURL: API.BASE_URL,
    timeout: 3000,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    }
  });
  return instance
}
