import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { categoryDefaultState, categoryPage } from "../interfaces/CategoryInterface";
import { AppState, AppThunk } from "../store";
import { setLoading } from "./loader.reducer";
import { performDeleteRequest, performGetRequest, performPostRequest, performPutRequest, performPutRequest_FormData, performPostRequest_FormData } from "../../constants/axios-utils";
import API from "../../shared/API-end-points";
import { MyToast, hasValue, responseHandler } from "../../Utils";

export const getCategory = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performGetRequest(API.category).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      const res_data = {
        rows: apiResponse?.data?.data ?? [],
        count: 1
      }
      dispatch(getCategoryList(res_data));
    }
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};
export const addCategory = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const form_data = new FormData();
  if (hasValue(payload?.id ?? "")) {
    form_data.append('id', payload.id);
  }
  form_data.append('name', payload.name);
  if (hasValue(payload?.file ?? "")) {
    form_data.append('file', payload.file);
  }
  await performPostRequest_FormData(API.category, form_data).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getCategory({}));
      navigate("/category")
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

export const deleteCategory = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performDeleteRequest(API.category + "/" + payload?.id ?? "").then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getCategory({}));
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

const initialState: categoryDefaultState = {
  categoryList: [],
  totalRecords: 0
};

export const categoryReducer = createSlice({
  name: "categoryState",
  initialState,
  reducers: {
    getCategoryList: (state, action: PayloadAction<categoryPage>) => ({
      ...state,
      categoryList: action.payload.rows,
      totalRecords: action.payload.count
    }),
  },
});

export const { getCategoryList } = categoryReducer.actions;

export const getCategoryListData = (state: AppState) => {
  return ({ rows: state.categoryReducer.categoryList, count: state.categoryReducer.totalRecords })
};

export { initialState as categoryState };
export default categoryReducer;
