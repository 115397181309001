import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { loaderDefaultState } from "../interfaces/LoaderInterface";
import { AppState } from "../store";

const initialState: loaderDefaultState = {
    loading: false
};

export const loaderReducer = createSlice({
  name: "loadingState",
  initialState,
  reducers: {
    setLoading: ( state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setLoading } = loaderReducer.actions;

export const getLoading = (state: AppState) => state.loaderReducer.loading;

export { initialState as loadingState };
export default loaderReducer;
