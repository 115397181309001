import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { breedDefaultState, breedPage } from "../interfaces/BreedInterface";
import { AppState, AppThunk } from "../store";
import { setLoading } from "./loader.reducer";
import { performDeleteRequest, performGetRequest, performPostRequest, performPutRequest, performPutRequest_FormData, performPostRequest_FormData } from "../../constants/axios-utils";
import API from "../../shared/API-end-points";
import { MyToast, hasValue, responseHandler } from "../../Utils";

export const getBreed = (payload: any): AppThunk => async (dispatch: any) => {
  if (!hasValue(payload?.loading ?? "")) {
    dispatch(setLoading(true));
  }
  await performGetRequest(API.breed + payload.url).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      const res_data = {
        rows: apiResponse?.data?.data ?? [],
        count: 1
      }
      dispatch(getBreedList(res_data));
    }
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};
export const addBreed = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  console.log(payload, 'payload');

  const form_data = new FormData();
  if (hasValue(payload?.id ?? "")) {
    form_data.append('id', payload.id);
  }
  form_data.append('name', payload.name);
  if (hasValue(payload?.file ?? "")) {
    form_data.append('file', payload.file);
  }
  form_data.append('categoryId', payload.categoryId);
  await performPostRequest_FormData(API.breed, form_data).then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getBreed({
        page: 1,
        limit: 10,
        url: ""
      }));
      navigate("/breed")
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

export const deleteBreed = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performDeleteRequest(API.breed + "/" + payload?.id ?? "").then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getBreed({
        page: 1,
        limit: 10,
        url: ""
      }));
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

const initialState: breedDefaultState = {
  breedList: [],
  totalRecords: 0
};

export const breedReducer = createSlice({
  name: "breedState",
  initialState,
  reducers: {
    getBreedList: (state, action: PayloadAction<breedPage>) => ({
      ...state,
      breedList: action.payload.rows,
      totalRecords: action.payload.count
    }),
  },
});

export const { getBreedList } = breedReducer.actions;

export const getBreedListData = (state: AppState) => {
  return ({ rows: state.breedReducer.breedList, count: state.breedReducer.totalRecords })
};

export { initialState as breedState };
export default breedReducer;
