import * as React from 'react';
import { Link } from "react-router-dom";

const SideBar = () => {
    return (
        <ul id="dc_accordion" className="sidebar-menu sidebar_custome tree">
            <li>
                <Link to="/home">
                    <i className="fa fa-home"></i>
                    <span>Dashboard</span>
                </Link>
            </li>
            <li className="menu_sub">
                <div id='accordion' className='accordion drop_custm'>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#banner">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Banner</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="banner" className='card-body collapse' data-parent="#accordion">
                        <Link to="/banner">Banner List</Link>
                    </div>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#category">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Category</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="category" className='card-body collapse' data-parent="#accordion">
                        <Link to="/category">Category List</Link>
                    </div>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#breed">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Breed</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="breed" className='card-body collapse' data-parent="#accordion">
                        <Link to="/breed">Breed List</Link>
                    </div>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#stock">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Stock</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="stock" className='card-body collapse' data-parent="#accordion">
                        <Link to="/stock">Stock List</Link>
                    </div>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#enquiry">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Enquiry</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="enquiry" className='card-body collapse' data-parent="#accordion">
                        <Link to="/enquiry">Enquiry List</Link>
                    </div>

                    <div className='card-header collapsed' data-toggle="collapse" data-target="#settings">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Settings</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="settings" className='card-body collapse' data-parent="#accordion">
                        <Link to="/settings">Settings</Link>
                    </div>

                </div>
            </li>
        </ul>
    );
}

export default SideBar;