import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { addBreed } from '../../../redux/reducers/breed.reducer';
import { useNavigate, useLocation } from "react-router-dom";
import { hasValue } from '../../../Utils';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { Loader } from '../../../common/components/Loader';
import { getCategoryListData } from '../../../redux/reducers/category.reducer';
import { getCategory } from '../../../redux/reducers/category.reducer';

const AddBreed = () => {
    const loader = useSelector(getLoading);
    let location = useLocation();
    const { itemData } = location.state;
    console.log(itemData, 'itemData');
    const breadCrums = [{ link: 'Breed', title: 'Breed' }];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState("");
    const categoryListData = useSelector(getCategoryListData);
    const [categoryData, setCategoryData] = useState([]);

    const [formState, setFormState] = useState({
        name: { error: "", value: "" },
        file: { error: "", value: "" },
        categoryId: { error: "", value: "" }
    });
    function hasFormValueChanged(model) {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    useEffect(() => {
        dispatch(getCategory({}));
        const id = itemData?.id ?? ""
        if (hasValue(id)) {
            setFormState({
                name: { error: "", value: itemData?.name ?? "" },
                // file: { error: "", value: itemData?.image ?? "" },
                file: { error: "", value: "" },
                categoryId: { error: "", value: itemData?.categoryId ?? "" },
            })
            setFile(itemData?.path ?? "")
        }
    }, [itemData]);

    useEffect(() => {
        try {
            const listData = categoryListData?.rows ?? []
            let tmpData = []
            listData.forEach(element => {
                tmpData.push({
                    key: element.id, value: element.name
                })
            });
            setCategoryData(tmpData)
        } catch (error) {
            console.log(error);
        }
    }, [categoryListData]);

    const submit = async () => {
        try {
            if (isFormInvalid()) {
                return
            }
            let payloads = {}
            if (hasValue(itemData?.id ?? "")) {
                payloads = {
                    id: itemData?.id ?? "",
                    name: formState.name.value,
                    file: formState.file.value,
                    categoryId: formState.categoryId.value
                }
            } else {
                payloads = {
                    name: formState.name.value,
                    file: formState.file.value,
                    categoryId: formState.categoryId.value
                }
            }
            dispatch(addBreed(payloads, navigate));
        } catch (error) {
            console.log(error);
        }
    }
    console.log(loader, 'loader');

    function isFormInvalid() {
        return (formState.name.error || !formState.name.value && formState.file.error || !formState.file.value && formState.categoryId.error || !formState.categoryId.value);
    }
    function getDisabledClass() {
        let isError = isFormInvalid();
        return isError ? "disabled" : "";
    }

    function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setFile(URL.createObjectURL(img))
            hasFormValueChanged({
                "value": event.target.files[0],
                "error": "",
                "touched": false,
                "field": "file"
            })
        }
    };
    const onPressCategory = (e) => {
        hasFormValueChanged({
            "value": e.target.value,
            "error": "",
            "touched": false,
            "field": "categoryId"
        })
    };

    const renderCategory = () => {
        return categoryData.map((item) => {
            return (
                <option value={item.key} key={item.value}>
                    {item.value}
                </option>
            );
        });
    };
    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={hasValue(itemData) ? 'Update Breed' : 'Add Breed'} page={breadCrums} />
                    {loader ?
                        (<Loader />) :
                        (<div className="row">
                            <div className=" col-md-12">
                                <div className="card card-shadow mb-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            Breed
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                            <div className="form-group">
                                                <TextInput id="name"
                                                    field="name"
                                                    value={formState.name.value}
                                                    onChange={hasFormValueChanged}
                                                    required={true}
                                                    label="Name"
                                                    placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                {file &&
                                                    <img style={{ height: 150, width: 150 }} src={file} />
                                                }
                                                <input type="file" name="myImage" onChange={onImageChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="sizeOfCategorySelect">Select Category</label>
                                                <select
                                                    className="form-control"
                                                    id="sizeOfCategorySelect"
                                                    value={formState.categoryId.value}
                                                    onChange={(e) => {
                                                        onPressCategory(e);
                                                    }}>
                                                    <option value={''} key={''}>Select Category</option>
                                                    {renderCategory()}
                                                </select>
                                            </div>
                                            <button
                                                className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                                type="submit" >
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default AddBreed;

