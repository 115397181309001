import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { getBreedListData, } from '../../../redux/reducers/breed.reducer';
import { breedItem } from '../../../redux/interfaces/BreedInterface';
import { getBreed, deleteBreed } from '../../../redux/reducers/breed.reducer';
import swal from 'sweetalert';

function BreedList() {
  const dispatch = useDispatch();
  const itemData = useSelector(getBreedListData);
  const breadCrums = [{ link: 'Breed', title: 'Breed' }];
  const loader = useSelector(getLoading);

  const [listData, setListData] = useState<breedItem[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const filterRow = (row: any) => {
    const copyrow = { ...row }
    delete copyrow.tag
    delete copyrow.deletedAt
    return [copyrow]
  }

  const DeleteRecord = async (payload: any) => {
    dispatch(deleteBreed(payload));
  }
  const deleteBreedBatch = (payload: {}) => {
    swal("Are you sure?", "You want to delete this!", "warning", {
      buttons: ["CANCEL", "DELETE"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        DeleteRecord(payload);
      }
    });
  };

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, index: any) => index + 1
    },
    {
      name: 'Name',
      selector: (row: any) => row.name
    },
    {
      name: 'Category',
      selector: (row: any) => row?.category?.name ?? ""
    },
    {
      name: 'File',
      selector: (row: any) => {
        let id = row.id;
        return (
          <img style={{ height: 100, width: 100 }} src={row?.path ?? ""} />
        );
      },
    },
    // {
    //   name: 'Created At',
    //   selector: (row: any) => row.createdAt,
    //   cell: (row: any) => <DateTime date={row.createdAt} />
    // },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link state={{ itemData: row }} className="dropdown-item" to={'AddBreed'}>Update</Link>
              <a className="dropdown-item"
                onClick={() => deleteBreedBatch({ id: row.id, type: "11", delete_type: "1" })}
              >Delete</a>
            </div>
          </div>
        );
      },
    }
  ];
  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async (page = 1) => {
    let payload = {
      page: page,
      limit: perPage,
      url: ""
    };
    dispatch(getBreed(payload));
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page);
    fetchData(page);
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setListData(itemData.rows);
    setTotalRows(itemData.count);
  }, [itemData]);

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Breed'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='text-right mb-2'>
              <Link state={{ itemData: null }} className="btn btn-primary" to={'AddBreed'}>Create Breed</Link>
            </div>
          </div>
          <DataTable
            title="Breed"
            columns={columns}
            data={listData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default BreedList;