import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import Footer from '../pages/layout/footer';
import Header from '../pages/layout/header';
import AddBanner from '../pages/banner/add';
import BannerList from '../pages/banner/list';

import AddCategory from '../pages/category/add';
import CategoryList from '../pages/category/list';

import AddBreed from '../pages/breed/add/index';
import BreedList from '../pages/breed/list';

import AddStock from '../pages/stock/add/index';
import StockList from '../pages/stock/list';

import AddEnquiry from '../pages/enquiry/add/index';
import EnquiryList from '../pages/enquiry/list';


import AddSettings from '../pages/settings/add/index';
import SettingsList from '../pages/settings/list';

const AppRouter = () => {
    return (
        <HashRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="banner">
                    <Route index={true} element={<BannerList />} />
                    <Route index={false} path="addBanner" element={<AddBanner />} />
                </Route>
                <Route path="category">
                    <Route index={true} element={<CategoryList />} />
                    <Route index={false} path="addCategory" element={<AddCategory />} />
                </Route>
                <Route path="breed">
                    <Route index={true} element={<BreedList />} />
                    <Route index={false} path="addBreed" element={<AddBreed />} />
                </Route>
                <Route path="stock">
                    <Route index={true} element={<StockList />} />
                    <Route index={false} path="addStock" element={<AddStock />} />
                </Route>
                <Route path="enquiry">
                    <Route index={true} element={<EnquiryList />} />
                    <Route index={false} path="AddEnquiry" element={<AddEnquiry />} />
                </Route>
                <Route path="settings">
                    <Route index={true} element={<SettingsList />} />
                    <Route index={false} path="AddSettings" element={<AddSettings />} />
                </Route>
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />

        </HashRouter>
    );
}

export default AppRouter;