import React from "react";
//import loader from "../../assets/loader.gif";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ContentLoader from 'react-content-loader'
import { TailSpin } from 'react-loader-spinner'

const Loader = () => {
    return (<div style={{ marginLeft: "40%", marginTop: "10%" }}><TailSpin color="#00BFFF" height={80} width={80} /></div>);
}

const LoaderBullet = () => {
    //return (<div style={{marginLeft: "40%", marginTop: "10%"}}><TailSpin color="#00BFFF" height={80} width={80}/></div>);
    return (
        <ContentLoader viewBox="0 0 380 100">
            {/* Only SVG shapes */}
            <rect x="10" y="20" rx="4" ry="4" width="90%" height="6" />
            <rect x="10" y="30" rx="4" ry="4" width="90%" height="6" />
            <rect x="10" y="40" rx="4" ry="4" width="90%" height="6" />
            <rect x="10" y="50" rx="4" ry="4" width="90%" height="6" />
            <rect x="10" y="60" rx="4" ry="4" width="90%" height="6" />
            <rect x="10" y="70" rx="4" ry="4" width="90%" height="6" />

        </ContentLoader>
    );
}

export { LoaderBullet, Loader };