import React from 'react';
import { Link } from 'react-router-dom';
import { Success } from '../../common/components/Alert';

function BreadCrum({title, page}: {title: any, page: any}) {
    
    const show = () => {
        return page.map((item: any, key: any) => {
            return (
                <li key={key}>
                    <Link to="#">
                        {item.title}
                    </Link>
                </li>
            );
        })
    }

    return (
        <div className="page-heading">
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <div className="page-breadcrumb">
                        <h1>{title}</h1>
                    </div>
                </div>
                <div className="col-md-6 justify-content-md-end d-md-flex">
                    <div className="breadcrumb_nav">
                        <ol className="breadcrumb">
                            <li>
                                <i className="fa fa-home"></i>
                                <Link className="parent-item" to="/">Home</Link>
                                <i className="fa fa-angle-right"></i>
                            </li>
                            {show()}                            
                        </ol>
                    </div>
                </div>
            </div>            
            <Success />
        </div>
    );
}

export default BreadCrum;