import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { addSettings } from '../../../redux/reducers/settings.reducer';
import { useNavigate, useLocation } from "react-router-dom";
import { hasValue } from '../../../Utils';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { Loader } from '../../../common/components/Loader';
import { getSettings, deleteSettings } from '../../../redux/reducers/settings.reducer';
import { getSettingsListData, } from '../../../redux/reducers/settings.reducer';
import { settingsItem } from '../../../redux/interfaces/SettingsInterface';

const AddSettings = () => {
  const responseData = useSelector(getSettingsListData);
  const loader = useSelector(getLoading);
  let location = useLocation();
  // const { itemData } = location.state; 
  const breadCrums = [{ link: 'Settings', title: 'Settings' }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [itemData, setItemData] = useState("");
  console.log(itemData, 'itemData');
  const [listData, setListData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [formState, setFormState] = useState({
    mobile: { error: "", value: "" },
    email: { error: "", value: "" }
  });
  function hasFormValueChanged(model) {
    setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
  }

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setListData(responseData.rows);
    setItemData(responseData.rows[0])
    setTotalRows(responseData.count);
  }, [responseData]);

  console.log(listData, 'listData');

  const fetchData = async (page = 1) => {
    let payload = {
      page: page,
      limit: perPage
    };
    dispatch(getSettings(payload));
  };

  useEffect(() => {
    const mobile = itemData?.supportNumber ?? ""
    if (hasValue(mobile)) {
      setFormState({
        mobile: { error: "", value: itemData?.supportNumber ?? "" },
        email: { error: "", value: itemData?.supportEmail ?? "" }
      })
      setFile(itemData?.path ?? "")
    }
  }, [itemData]);

  const submit = async () => {
    try {
      if (isFormInvalid()) {
        return
      }
      let payloads = {}
      if (hasValue(itemData?.supportNumber ?? "")) {
        payloads = {
          // id: itemData?.id ?? "",
          supportNumber: formState.mobile.value,
          supportEmail: formState.email.value,
          // "test": "Check"
        }
      } else {
        payloads = {
          supportNumber: formState.mobile.value,
          supportEmail: formState.email.value,
          // "test": "Check"
        }
      }
      dispatch(addSettings(payloads, navigate));
    } catch (error) {
      console.log(error);
    }
  }

  function isFormInvalid() {
    return (formState.mobile.error || !formState.mobile.value || formState.email.error || !formState.email.value);
  }
  function getDisabledClass() {
    let isError = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFile(URL.createObjectURL(img))
      hasFormValueChanged({
        "value": event.target.files[0],
        "error": "",
        "touched": false,
        "field": "file"
      })
    }
  };

  return (
    <div className="container_full">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid">
          <BreadCrum title={'Settings'} page={breadCrums} />
          {loader ?
            (<Loader />) :
            (<div className="row">
              <div className=" col-md-12">
                <div className="card card-shadow mb-4">
                  <div className="card-header">
                    <div className="card-title">
                      Settings
                                        </div>
                  </div>
                  <div className="card-body">
                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                      <div className="form-group">
                        <TextInput id="mobile"
                          field="mobile"
                          value={formState.mobile.value}
                          onChange={hasFormValueChanged}
                          required={true}
                          label="Mobile"
                          placeholder="Mobile" />
                      </div>
                      <div className="form-group">
                        <TextInput id="email"
                          field="email"
                          value={formState.email.value}
                          onChange={hasFormValueChanged}
                          required={true}
                          label="Email"
                          placeholder="Email" />
                      </div>
                      <button
                        className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                        type="submit" >
                        Submit
                                            </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
    </div>
  );
}

export default AddSettings;

