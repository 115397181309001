import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { enquiryDefaultState, enquiryPage } from "../interfaces/EnquiryInterface";
import { AppState, AppThunk } from "../store";
import { setLoading } from "./loader.reducer";
import { performDeleteRequest, performGetRequest, performPostRequest, performPutRequest, performPutRequest_FormData, performPostRequest_FormData } from "../../constants/axios-utils";
import API from "../../shared/API-end-points";
import { MyToast, hasValue, responseHandler } from "../../Utils";

export const getEnquiry = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performGetRequest(API.enquiry + payload?.url ?? "").then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      const res_data = {
        rows: apiResponse?.data?.data ?? [],
        count: 500
      }
      dispatch(getEnquiryList(res_data));
    }
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};
export const deleteEnquiry = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  await performDeleteRequest(API.enquiry + "/" + payload?.id ?? "").then((res) => {
    const apiResponse = responseHandler(res)
    if (hasValue(apiResponse)) {
      dispatch(getEnquiry({}));
    }
    MyToast(apiResponse?.data?.msg ?? "")
    dispatch(setLoading(false));
  }).catch(error => {
    const apiResponse = responseHandler(error.response);
    dispatch(setLoading(false));
  })
};

const initialState: enquiryDefaultState = {
  enquiryList: [],
  totalRecords: 0
};

export const enquiryReducer = createSlice({
  name: "enquiryState",
  initialState,
  reducers: {
    getEnquiryList: (state, action: PayloadAction<enquiryPage>) => ({
      ...state,
      enquiryList: action.payload.rows,
      totalRecords: action.payload.count
    }),
  },
});

export const { getEnquiryList } = enquiryReducer.actions;

export const getEnquiryListData = (state: AppState) => {
  return ({ rows: state.enquiryReducer.enquiryList, count: state.enquiryReducer.totalRecords })
};

export { initialState as enquiryState };
export default enquiryReducer;
